import Upload from './comps/Upload/Upload';
import Browser from './comps/Browser/Browser';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
    return (
        <>
            <div id='background'></div>
            <div id="content">
                <Router>
                    <Routes>
                        <Route path="/" element={<Upload />} />
                        <Route path="/contents" element={<Browser />} />
                    </Routes>
                </Router>
            </div>
        </>
    );
}

export default App;
