import { faJava } from '@fortawesome/free-brands-svg-icons';
import { faCode, faCompactDisc, faDesktop, faFile, faFileAudio, faFileExcel, faFileImage, faFileLines, faFilePdf, faFileWord, faFileZipper, faFolderOpen, faImage, faVideo } from '@fortawesome/free-solid-svg-icons'
const { DateTime } = require("luxon");

export function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

export function secondsToHumanReadable(seconds) {
    seconds = parseInt(seconds, 10)
    var hours = Math.floor(seconds / 3600)
    var minutes = Math.floor((seconds - (hours * 3600)) / 60)
    seconds = seconds - (hours * 3600) - (minutes * 60)
    if (!!hours) {
        if (!!minutes) {
            return `${hours}h ${minutes}m ${seconds}s`
        } else {
            return `${hours}h ${seconds}s`
        }
    }
    if (!!minutes) {
        return `${minutes}m ${seconds}s`
    }
    return `${seconds}s`
}

export function convertDateTime(date) {
    return DateTime.fromISO(date).setLocale(navigator.language).toLocaleString(DateTime.DATETIME_SHORT).replace(",", "");
}

export function convertDateTimeWithSeconds(date) {
    return DateTime.fromISO(date).setLocale(navigator.language).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS).replace(",", "");
}

export function timeSince(date) {
    return DateTime.fromISO(date).toRelative({ locale: 'en', padding: 1000 });
}

export function timeSinceRelative(date) {
    return DateTime.fromISO(date).toRelativeCalendar({ locale: 'en' });
}

export function convertTime(time) {
    return DateTime.fromISO(time).setLocale(navigator.language).toFormat('hh:mm a').replace(",", "");
}

export function convertTimeWithSeconds(time) {
    return DateTime.fromISO(time).setLocale(navigator.language).toLocaleString(DateTime.TIME_WITH_SECONDS).replace(",", "");
}

export function convertDate(date) {
    return DateTime.fromISO(date).setLocale(navigator.language).toFormat('MM/dd/yyyy').replace(",", "");
}

export function duration(date) {
    return DateTime.now().diff(DateTime.fromISO(date).setLocale(navigator.language), ['days', 'hours']).toObject();
}

export function isEllipsisActive(e) {
    return e.scrollWidth > e.offsetWidth;
}

export function formatNumber(x) {
    if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return null;
    }
}

export function searchObject(obj, propertyName, searchTerm) {
    let results = [];
    iterator(obj);
    function iterator(newObj) {
        for (var property in newObj) {
            if (typeof newObj[property] === "object") {
                iterator(newObj[property]);
            } else if (property === propertyName && newObj[property].toLowerCase().includes(searchTerm.toLowerCase())) {
                results.push(newObj)
            }
        }
    }
    return results;
}

//. Counts number of files in folder and subfolders
export function countDirtree(obj) {
    let count = 0;
    iterator(obj);
    function iterator(obj) {
        for (var property in obj) {
            if (obj[property].name && obj[property].type === 'file') {
                count += 1;
            }

            if (typeof obj[property] === 'object') {
                iterator(obj[property])
            }
        }
    }
    return count;
}

export function genIcon(file) {
    let type = file.type;
    let ext = file.name.split('.').pop().toLowerCase();

    if (type === 'directory') {
        return faFolderOpen
    }

    switch (ext) {
        case 'mp4':
        case 'mkv':
        case 'avi':
        case 'webm':
        case 'flv':
        case 'vob':
        case 'ogv':
        case 'gifv':
        case 'mov':
        case '3gp':
        case '3g2':
        case 'wmv':
        case 'mpeg-1':
        case 'mpeg-2':
        case 'mpeg-4':
        case 'mpegps':
        case 'mpg':
        case 'qt':
            return faVideo
        case 'mp3':
        case 'ogg':
        case 'aac':
        case 'flac':
        case 'alac':
        case 'wav':
        case 'aiff':
        case 'm4a':
        case 'wma':
        case 'pcm':
            return faFileAudio
        case 'exe':
        case 'msi':
        case 'app':
        case 'bat':
        case 'ps1':
        case 'cmd':
        case 'run':
        case 'sh':
        case 'vbscript':
            return faDesktop
        case 'js':
        case 'jsx':
        case 'mjs':
        case 'cjs':
        case 'go':
        case 'java':
        case 'php':
        case 'swift':
        case 'vb':
        case 'wsf':
        case 'htm':
        case 'html':
        case 'xhtml':
        case 'css':
        case 'asp':
        case 'aspx':
        case 'scss':
        case 'cs':
        case 'cpp':
        case 'jsp':
        case 'cgi':
        case 'pl':
        case 'py':
        case 'asm':
        case 'a51':
        case 'inc':
        case 'nasm':
        case 'awk':
        case 'auk':
        case 'gawk':
        case 'mawk':
        case 'nawk':
        case 'h':
        case 'idc':
        case 'w':
        case 'cats':
        case 'cake':
        case 'cshtml':
        case 'csx':
        case 'c++':
        case 'h++':
        case 'hpp':
        case 'hxx':
        case 'tcc':
        case 'tpp':
        case 'cmake':
        case 'd':
        case 'dockerfile':
        case 'el':
        case 'elm':
        case 'erl':
        case 'es':
        case 'escript':
        case 'fs':
        case 'f90':
        case 'f':
        case 'f03':
        case 'f08':
        case 'f77':
        case 'f95':
        case 'for':
        case 'fpp':
        case 'ts':
        case 'less':
        case 'lua':
        case 'mkfile':
        case 'mk':
        case 'rb':
        case 'rs':
            return faCode
        case 'tif':
        case 'tiff':
        case 'bpm':
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'png':
        case 'raw':
        case 'cr2':
        case 'nef':
        case 'orf':
        case 'sr2':
        case 'jfif':
        case 'webp':
        case 'svg':
        case 'cr3':
        case 'ico':
            return faImage
        case 'docx':
        case 'docm':
        case 'doc':
        case 'dot':
            return faFileWord
        case 'xla':
        case 'xlam':
        case 'xll':
        case 'xlm':
        case 'xls':
        case 'xlsx':
        case 'xlt':
        case 'xlsm':
            return faFileExcel
        case 'txt':
        case 'xml':
        case 'json':
        case 'ini':
        case 'rtf':
        case 'odt':
            return faFileLines
        case 'pdf':
            return faFilePdf
        case 'iso':
            return faCompactDisc
        case 'zip':
        case '7z':
        case 'rar':
        case 'tar':
        case 'ar':
        case 'br':
        case 'zipx':
        case 'gz':
        case 'rpm':
            return faFileZipper
        case 'jar':
            return faJava
        case 'dng':
        case 'psd':
            return faFileImage
        default:
            return faFile;
    }
}