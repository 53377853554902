/*eslint no-loop-func: "off"*/
import React, { useEffect, useState, useMemo, useRef } from 'react';
import axios from 'axios';
import { useVirtual, List } from '@af-utils/react-virtual-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faCircleXmark, faMagnifyingGlass, faTurnUp } from '@fortawesome/free-solid-svg-icons'
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale-subtle.css';
import * as funcs from '../../functions';
import './Browser.scss';
const urlParams = new URLSearchParams(window.location.search);

let dirtree = [];
let loaded = false;
let tooltipTimeout = null;

export default function Browser() {
    const [entries, setEntries] = useState([]);
    const [sorter, setSorter] = useState('rname');
    const [filter, setFilter] = useState(null);
    const [tooltip, setTooltip] = useState(null);
    const [count, setCount] = useState(null);
    const searchRef = useRef(null);
    const memoizedValue = useMemo(() => ({ i, data }) => (data[i]), []);
    const model = useVirtual({
        itemCount: entries.length,
        estimatedItemSize: 40,
        overscanCount: 5,
        estimatedWidgetSize: 0
    });

    //. Loads inital dirtree
    useEffect(() => {
        axios({
            method: 'post',
            url: `/api/getContents`,
            data: { directory: urlParams.get('dir') ? urlParams.get('dir') : '' }
        }).then(async (msg) => {
            if (!urlParams.get('search')) {
                dirtree = msg.data.children;
            } else {
                dirtree = funcs.searchObject(msg.data, 'name', urlParams.get('search'));
            }
            loaded = true;
            createEntires();
        })
        // eslint-disable-next-line
    }, [])

    //. Redraw list when sorted, filtered, or on tooltip update
    useEffect(() => {
        createEntires();
        // eslint-disable-next-line
    }, [sorter, tooltip, filter])

    //. Updates search query string
    function updateSearchQuery() {
        if (searchRef.current.value === '') {
            //* Clear search query
            urlParams.delete('search')
            window.location.href = ({}, '', `${window.location.pathname}?${urlParams}`)
        } else {
            //* Update search query
            urlParams.set('search', searchRef.current.value)
            window.location.href = ({}, '', `${window.location.pathname}?${urlParams}`)
        }
    }

    //. Returns breadcrumb element
    function createBreadcrumbs() {
        if (!urlParams.get('dir') && !urlParams.get('search')) {
            //* At root directory
            return (<a href='/'><b>Upload</b></a>)
        } else if (!urlParams.get('dir') && urlParams.get('search')) {
            //* At root directory but searching
            return (<a href='/contents'><b>Home</b></a>)
        }

        //* Not at root directory and not searching
        let fullPath = urlParams.get('dir').split('/');
        for (let i = 0; i < fullPath.length; i++) {
            //* Add slash if not existing already
            if (!fullPath[i].includes('/')) {
                fullPath[i] += '/';
            }
        }

        let links = fullPath.map((elem, index) => fullPath.slice(0, index + 1).reduce((a, b) => a + b));

        return (
            <>
                <a href='/contents'><b>Home</b></a>
                {urlParams.get('dir')?.split('/').map((result, i) => {
                    return (
                        <a key={i} href={`/contents?dir=${links[i]}`}>{` / ${result}`}</a>
                    )
                })}
            </>
        )
    }

    //. Recreate and redraw all entries
    function createEntires() {
        if (loaded === false) {
            setEntries([<img src='../images/loading-circle.gif' alt='Loading...' className='loading-image'></img>])
            return
        }

        if (dirtree.length === 0 && urlParams.get('search')) {
            //* Return no results found if searching and dirtree is empty
            setEntries([<span className='no-results'>No Results Found</span>])
            return
        }

        //* Sort entries
        if (sorter === 'time') {
            dirtree = dirtree.sort(function (a, b) {
                let dateA = new Date(a.mtime).valueOf();
                let dateB = new Date(b.mtime).valueOf();
                return dateB - dateA;
            });
        } else if (sorter === 'rtime') {
            dirtree = dirtree.sort(function (a, b) {
                let dateA = new Date(a.mtime).valueOf();
                let dateB = new Date(b.mtime).valueOf();
                return dateA - dateB;
            });
        } else if (sorter === 'size') {
            dirtree = dirtree.sort(function (a, b) {
                let valueA = a.size.valueOf();
                let valueB = b.size.valueOf();
                return valueB - valueA;
            });
        } else if (sorter === 'rsize') {
            dirtree = dirtree.sort(function (a, b) {
                let valueA = a.size.valueOf();
                let valueB = b.size.valueOf();
                return valueA - valueB;
            });
        } else if (sorter === 'count') {
            dirtree = dirtree.sort(function (a, b) {
                let valueA = funcs.countDirtree(a);
                let valueB = funcs.countDirtree(b);
                if (valueA === undefined) { valueA = 0 }
                if (valueB === undefined) { valueB = 0 }
                return valueB - valueA;
            });
        } else if (sorter === 'rcount') {
            dirtree = dirtree.sort(function (a, b) {
                let valueA = funcs.countDirtree(a);
                let valueB = funcs.countDirtree(b);
                if (valueA === undefined) { valueA = 0 }
                if (valueB === undefined) { valueB = 0 }
                return valueA - valueB;
            });
        } else if (sorter === 'name') {
            dirtree.sort((a, b) => -1 * a.name.localeCompare(b.name))
        } else if (sorter === 'rname') {
            dirtree.sort((a, b) => a.name.localeCompare(b.name))
        }

        let newEntries = [];
        let count = 0;

        //* Create up directory entry if NOT in root and NOT searching
        if (urlParams.get('dir') && !urlParams.get('search')) {
            newEntries.push(
                <div className='entry'>
                    <span className='icon-col'>
                        <FontAwesomeIcon icon={faTurnUp} style={{ transform: 'scaleX(-1)', width: '50px' }}></FontAwesomeIcon>
                    </span>
                    <a
                        className='name-col'
                        style={{ color: '#b7b7b7' }}
                        href={urlParams.get('dir').includes('/') ?
                            '?dir=' + urlParams.get('dir').substring(0, urlParams.get('dir').lastIndexOf("/")) :
                            '/contents'
                        }
                    >Up Directory</a>
                    <span className='count-col'></span>
                    <span className='size-col'></span>
                    <span className='mtime-date-col'></span>
                    <span className='mtime-time-col'></span>
                </div>
            )
        }

        //* Creates individual entries
        for (let i = 0; i < dirtree.length; i++) {
            let currentEntry = dirtree[i];

            if (currentEntry.name === "Screenshots" || currentEntry.name === "sawmill-log.txt" || currentEntry.name === "foreman-log.txt") {
                continue
            }

            //* Skip if entry does NOT match filter criteria
            if (filter === '#' && isNaN(currentEntry.name[0]) === true) {
                continue
            } else if (filter !== null && filter !== '#' && currentEntry.name[0].toLowerCase() !== filter) {
                continue
            }

            //* Push new entry to state
            count++;
            newEntries.push(
                <div key={i} className='entry'>
                    {/* Icon column */}
                    <span className='icon-col'>
                        <FontAwesomeIcon icon={funcs.genIcon(currentEntry)} style={{ width: '50px' }}></FontAwesomeIcon>
                    </span>

                    {/* Name column */}
                    < a
                        className='name-col'
                        target={currentEntry.type === 'file' ? "_blank" : ""}
                        rel="noreferrer"
                        href={currentEntry.type === 'file' ?
                            currentEntry.path :
                            `?dir=${currentEntry.path.replace('files/', '').replace('files\\', '')}`
                        }
                        onMouseEnter={(e) => {
                            clearTimeout(tooltipTimeout);
                            tooltipTimeout = setTimeout(function () {
                                if (funcs.isEllipsisActive(e.target)) {
                                    setTooltip(currentEntry.name);
                                }
                            }, 250);
                        }}
                        onMouseLeave={() => {
                            clearTimeout(tooltipTimeout);
                            setTooltip(null);
                        }}
                    >
                        {tooltip !== null ?
                            <Tippy
                                content={currentEntry.name}
                                animation='scale-subtle'
                                theme='material'
                                plugins={[followCursor]}
                                maxWidth={1000}
                                followCursor={true}
                            >
                                <span style={{ display: 'unset' }}>
                                    {urlParams.get('search') ?
                                        currentEntry.path.replace('files/', '') :
                                        currentEntry.name
                                    }
                                </span>
                            </Tippy> :
                            <>
                                {urlParams.get('search') ?
                                    currentEntry.path.replace('files/', '') :
                                    currentEntry.name
                                }
                            </>
                        }
                    </a >

                    {/* Count column */}
                    <span className='count-col'>
                        {currentEntry.type !== 'file' &&
                            funcs.formatNumber(funcs.countDirtree(currentEntry, 'name'))
                        }
                    </span>

                    {/* Size column */}
                    <span className='size-col'>{funcs.humanFileSize(currentEntry.size, true)}</span>

                    {/* Modified column */}
                    <span className='mtime-date-col'>{funcs.convertDate(currentEntry.mtime)}</span>
                    <span className='mtime-time-col'>{funcs.convertTime(currentEntry.mtime)}</span>
                </div >
            )
        }
        setCount(count);
        setEntries([...newEntries]);
    }

    //. Main comp return
    return (
        <div id="browser-content">
            {/* Toolbar */}
            <div className='toolbar'>
                <div id='search-div'>
                    {/* Search bar */}
                    <input
                        type="text"
                        id='search-bar'
                        placeholder='Search'
                        ref={searchRef}
                        defaultValue={urlParams.get('search')}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                updateSearchQuery();
                            }
                        }}
                    />

                    {/* Search button */}
                    <FontAwesomeIcon className='grow search-button' icon={faMagnifyingGlass} onClick={() => { updateSearchQuery() }}></FontAwesomeIcon>
                    {urlParams.get('search') &&
                        <FontAwesomeIcon className='grow search-button' icon={faCircleXmark} onClick={() => {
                            urlParams.delete('search');
                            window.location.href = ({}, '', `${window.location.pathname}?${urlParams}`);
                        }}>
                        </FontAwesomeIcon>
                    }

                    {/* Result field */}
                    {count > 0 &&
                        <span className='result-count'>{`(${funcs.formatNumber(count)} ${count === 1 ? 'Result' : 'Results'})`}</span>
                    }
                </div>

                {/* Breadcrumbs */}
                <div className='breadcrumbs'>{createBreadcrumbs()}</div>
                <div className='filter'>
                    <span onClick={() => { setFilter(null) }} style={filter === null ? { fontWeight: 'bold' } : { fontWeight: '' }}>ALL</span>
                    <span onClick={() => { setFilter('#') }} style={filter === '#' ? { fontWeight: 'bold' } : { fontWeight: '' }}>#</span>
                    <span onClick={() => { setFilter('a') }} style={filter === 'a' ? { fontWeight: 'bold' } : { fontWeight: '' }}>A</span>
                    <span onClick={() => { setFilter('b') }} style={filter === 'b' ? { fontWeight: 'bold' } : { fontWeight: '' }}>B</span>
                    <span onClick={() => { setFilter('c') }} style={filter === 'c' ? { fontWeight: 'bold' } : { fontWeight: '' }}>C</span>
                    <span onClick={() => { setFilter('d') }} style={filter === 'd' ? { fontWeight: 'bold' } : { fontWeight: '' }}>D</span>
                    <span onClick={() => { setFilter('e') }} style={filter === 'e' ? { fontWeight: 'bold' } : { fontWeight: '' }}>E</span>
                    <span onClick={() => { setFilter('f') }} style={filter === 'f' ? { fontWeight: 'bold' } : { fontWeight: '' }}>F</span>
                    <span onClick={() => { setFilter('g') }} style={filter === 'g' ? { fontWeight: 'bold' } : { fontWeight: '' }}>G</span>
                    <span onClick={() => { setFilter('h') }} style={filter === 'h' ? { fontWeight: 'bold' } : { fontWeight: '' }}>H</span>
                    <span onClick={() => { setFilter('i') }} style={filter === 'i' ? { fontWeight: 'bold' } : { fontWeight: '' }}>I</span>
                    <span onClick={() => { setFilter('j') }} style={filter === 'j' ? { fontWeight: 'bold' } : { fontWeight: '' }}>J</span>
                    <span onClick={() => { setFilter('k') }} style={filter === 'k' ? { fontWeight: 'bold' } : { fontWeight: '' }}>K</span>
                    <span onClick={() => { setFilter('l') }} style={filter === 'l' ? { fontWeight: 'bold' } : { fontWeight: '' }}>L</span>
                    <span onClick={() => { setFilter('m') }} style={filter === 'm' ? { fontWeight: 'bold' } : { fontWeight: '' }}>M</span>
                    <span onClick={() => { setFilter('n') }} style={filter === 'n' ? { fontWeight: 'bold' } : { fontWeight: '' }}>N</span>
                    <span onClick={() => { setFilter('o') }} style={filter === 'o' ? { fontWeight: 'bold' } : { fontWeight: '' }}>O</span>
                    <span onClick={() => { setFilter('p') }} style={filter === 'p' ? { fontWeight: 'bold' } : { fontWeight: '' }}>P</span>
                    <span onClick={() => { setFilter('q') }} style={filter === 'q' ? { fontWeight: 'bold' } : { fontWeight: '' }}>Q</span>
                    <span onClick={() => { setFilter('r') }} style={filter === 'r' ? { fontWeight: 'bold' } : { fontWeight: '' }}>R</span>
                    <span onClick={() => { setFilter('s') }} style={filter === 's' ? { fontWeight: 'bold' } : { fontWeight: '' }}>S</span>
                    <span onClick={() => { setFilter('t') }} style={filter === 't' ? { fontWeight: 'bold' } : { fontWeight: '' }}>T</span>
                    <span onClick={() => { setFilter('u') }} style={filter === 'u' ? { fontWeight: 'bold' } : { fontWeight: '' }}>U</span>
                    <span onClick={() => { setFilter('v') }} style={filter === 'v' ? { fontWeight: 'bold' } : { fontWeight: '' }}>V</span>
                    <span onClick={() => { setFilter('w') }} style={filter === 'w' ? { fontWeight: 'bold' } : { fontWeight: '' }}>W</span>
                    <span onClick={() => { setFilter('x') }} style={filter === 'x' ? { fontWeight: 'bold' } : { fontWeight: '' }}>X</span>
                    <span onClick={() => { setFilter('y') }} style={filter === 'y' ? { fontWeight: 'bold' } : { fontWeight: '' }}>Y</span>
                    <span onClick={() => { setFilter('z') }} style={filter === 'z' ? { fontWeight: 'bold' } : { fontWeight: '' }}>Z</span>
                </div>
            </div>

            {/* Table Header */}
            <div className='entry header noselect' >
                <span className='icon-col' style={{ width: '100px' }} onClick={() => { sorter === 'rname' ? setSorter('name') : setSorter('rname') }}>
                    <span>Name</span>
                    {(sorter === 'name' || sorter === 'rname') &&
                        <>
                            {sorter === 'rname' ?
                                <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: '5px' }}></FontAwesomeIcon> :
                                <FontAwesomeIcon icon={faChevronUp} style={{ marginLeft: '5px' }}></FontAwesomeIcon>
                            }
                        </>
                    }
                </span>
                <span className='name-col'></span>
                <span className='count-col' onClick={() => { sorter === 'rcount' ? setSorter('count') : setSorter('rcount') }}>
                    <span>Count</span>
                    {(sorter === 'count' || sorter === 'rcount') &&
                        <>
                            {sorter === 'rcount' ?
                                <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: '5px' }}></FontAwesomeIcon> :
                                <FontAwesomeIcon icon={faChevronUp} style={{ marginLeft: '5px' }}></FontAwesomeIcon>
                            }
                        </>
                    }
                </span>
                <span className='size-col' onClick={() => { sorter === 'rsize' ? setSorter('size') : setSorter('rsize') }}>
                    <span>Size</span>
                    {(sorter === 'size' || sorter === 'rsize') &&
                        <>
                            {sorter === 'rsize' ?
                                <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: '5px' }}></FontAwesomeIcon> :
                                <FontAwesomeIcon icon={faChevronUp} style={{ marginLeft: '5px' }}></FontAwesomeIcon>
                            }
                        </>
                    }
                </span>
                <span className='mtime-date-col' onClick={() => { sorter === 'rtime' ? setSorter('time') : setSorter('rtime') }}>
                    <span>Modified</span>
                    {(sorter === 'time' || sorter === 'rtime') &&
                        <>
                            {sorter === 'rtime' ?
                                <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: '5px' }}></FontAwesomeIcon> :
                                <FontAwesomeIcon icon={faChevronUp} style={{ marginLeft: '5px' }}></FontAwesomeIcon>
                            }
                        </>
                    }
                </span>
                <span className='mtime-time-col'></span>
            </div>

            {/* Virtual Table */}
            <List
                model={model}
                itemData={entries}
                getKey={entries.key}
            >
                {memoizedValue}
            </List>
        </div >
    );
}