/*eslint no-loop-func: "off"*/
import './Upload.scss';
import React, { useState, useRef, useEffect } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faMinus, faUpload, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import axios from 'axios';
import * as funcs from '../../functions';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale-subtle.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { isMobile } from 'react-device-detect';
const scrollIntoView = require('scroll-into-view');
const Alert = withReactContent(Swal);

let controller = new AbortController();
let uploadInfo = {
    uploadProgress: [],
    cancelled: [],
    concluded: [],
    currentlyProcessing: null,
    queueBytes: 0
}

export default function Upload() {
    const [files, setFiles] = useState([]);
    const [categories, setCategories] = useState(null);
    const [fileProgress, setFileProgress] = useState([]);
    const [uploadStats, setUploadStats] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [queueBytes, setQueueBytes] = useState(0);
    const categoryRef = useRef(null);

    //. Load categories
    useEffect(() => {
        axios({
            method: 'get',
            url: `/api/getCategories`,
        }).then(msg => {
            setCategories(msg.data);
        })
    }, [])

    //. Update queue bytes and names on file change
    useEffect(() => {
        setFileProgress([]);
        updateQueueBytes();
        for (let i = 0; i < document.getElementsByClassName('name-input').length; i++) {
            const nameField = document.getElementsByClassName('name-input')[i];
            nameField.value = files[i].name.substring(0, files[i].name.lastIndexOf('.'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files])

    //. Updates total bytes in queue, excluding cancelled files
    function updateQueueBytes() {
        let size = 0;
        for (let i = 0; i < files.length; i++) {
            if (!uploadInfo.cancelled.includes(i)) {
                size += files[i].size;
            }
        }
        uploadInfo.queueBytes = size;
        setQueueBytes(size);
    }

    //. Uploads all files found in file queue
    async function upload() {
        let startedAt = new Date();
        let totalUploaded = 0;

        uploadInfo = {
            uploadProgress: [],
            cancelled: [],
            concluded: [],
            currentlyProcessing: null,
            queueBytes: 0
        }

        updateQueueBytes();
        setUploading(true);

        //* Loop through each file in queue and upload
        for (let i = 0; i < files.length; i++) {
            uploadInfo.currentlyProcessing = i;
            uploadInfo.uploadProgress.push(0);

            if (uploadInfo.cancelled.includes(uploadInfo.currentlyProcessing)) {
                //! Skip file, found in 'cancelled' array
                uploadInfo.concluded.push(i);
                continue;
            }

            //* Scroll to currently uploading file if upload is NOT cancelled
            if (!controller.signal.aborted) {
                scrollIntoView(document.getElementsByClassName("category-input")[i]);
            }

            //* Gets category, name, extension, and form data of currently uploading file
            let name = document.getElementsByClassName("name-input")[i].value;
            let category = document.getElementsByClassName("category-input")[i].value !== "None" ? document.getElementsByClassName("category-input")[i].value : "";
            let extension = document.getElementsByClassName("extension")[i].innerHTML.toLowerCase();
            let formData = new FormData();
            formData.append("file", files[i]);

            //* Beging uploading
            await axios({
                method: 'post',
                data: formData,
                url: `/api/upload?category=${category}&name=${name}&extension=${extension}`,
                headers: { "Content-Type": "multipart/form-data" },
                signal: controller.signal,
                onUploadProgress: progress => {
                    //* Sets progress of currently uploading file
                    uploadInfo.uploadProgress[i] = (progress.progress * 100).toFixed(0);
                    setFileProgress([...uploadInfo.uploadProgress]);

                    //* Calculates overall time remaining
                    totalUploaded += progress.bytes
                    var secondsElapsed = (new Date().getTime() - startedAt.getTime()) / 1000;
                    var bytesPerSecond = totalUploaded / secondsElapsed;
                    var remainingBytes = uploadInfo.queueBytes - totalUploaded;
                    var secondsRemaining = remainingBytes / bytesPerSecond;

                    setUploadStats(
                        <>
                            {progress.estimated ?
                                <>
                                    <div>{`Uploading ${uploadInfo.currentlyProcessing + 1} / ${files.length - uploadInfo.cancelled.length} (${funcs.secondsToHumanReadable(progress.estimated)})`}</div>
                                    {files.length - uploadInfo.cancelled.length > 1 &&
                                        <div>{`${funcs.secondsToHumanReadable(secondsRemaining)} Remaining`}</div>
                                    }
                                    <div>{funcs.humanFileSize(progress.rate)}</div>
                                </> :
                                <>
                                    <div>{`Uploading ${uploadInfo.currentlyProcessing + 1} / ${files.length - uploadInfo.cancelled.length} (0s)`}</div>
                                    {files.length - uploadInfo.cancelled.length > 1 &&
                                        <div>{`${funcs.secondsToHumanReadable(secondsRemaining)} Remaining`}</div>
                                    }
                                    <div>-</div>
                                </>
                            }
                        </>
                    )

                    if (uploadInfo.cancelled.includes(i)) {
                        //! File cancelled during upload, abort and create a new controller so the next one doesn't automatically get cancelled
                        totalUploaded -= progress.loaded;
                        controller.abort();
                        controller = new AbortController();
                    }
                }
            }).catch(() => {
                //! Upload failed due to network error, set progress to -1 to indicated a failure, then set the state
                uploadInfo.uploadProgress[i] = -1;
                setFileProgress([...uploadInfo.uploadProgress]);
            })
            //* Current file finished
            uploadInfo.concluded.push(i);
        }

        //* All files finished uploading
        updateQueueBytes();
        uploadInfo.currentlyProcessing = null;
        uploadInfo.cancelled = [];
        uploadInfo.concluded = [];
        controller = new AbortController();
        setUploading(false);
    }

    //. Main comp return
    return (
        <div id="upload-content">
            {/* Logo */}
            <div id="logo">SkyDrive</div>

            {/* Box */}
            <a href='/contents'>
                <img id="box" src="../images/box.png" alt="Browse Archive"></img>
            </a>

            {/* File uploader */}
            <div id='dnd-div'>
                {!uploading ?
                    <FileUploader
                        name="file"
                        multiple={true}
                        handleChange={(files) => { setFiles(files) }}
                    >
                        {isMobile ?
                            <div>Touch to select files</div> :
                            <div>Drag and drop files here</div>
                        }
                    </FileUploader> :
                    <span id='uploading-text'>{uploadStats}</span>
                }
            </div>

            {files.length > 0 &&
                <>
                    <div id='file-div'>
                        {/* Tool bar */}
                        <div id='toolbar'>
                            <div>
                                {/* Upload summary */}
                                <span>{files.length - uploadInfo.cancelled.length + ` ${files.length > 1 ? "Files" : "File"} Queued (${funcs.humanFileSize(queueBytes, true)})`}</span>

                                {/* Clear queue button */}
                                <Tippy content='Clear upload queue' animation='scale-subtle' theme='custom'>
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        id='clear-queue-button'
                                        className='grow'
                                        onClick={() => {
                                            Alert.fire({
                                                title: <p>Clear upload queue?</p>,
                                                text: 'If you have renamed or categorized any files, this data will be lost and you will need to re-enter them again. Continue?',
                                                showCancelButton: true,
                                                confirmButtonText: 'Clear',
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    setFiles([]);
                                                }
                                            })
                                        }}
                                    >
                                    </FontAwesomeIcon>
                                </Tippy>
                            </div>

                            <div>
                                {/* Global category */}
                                <span style={{ marginRight: '10px' }}>Category</span>
                                <Tippy content='Globally changes ALL categories' animation='scale-subtle' theme='custom'>
                                    <select
                                        id='global-category'
                                        className='input'
                                        disabled={uploading ? true : false}
                                        ref={categoryRef}
                                        onChange={(e) => {
                                            let changed = false;
                                            for (let i = 1; i < document.getElementsByClassName('category-input').length; i++) {
                                                const categoryField = document.getElementsByClassName('category-input')[i];
                                                if (categoryField.value !== 'None') {
                                                    changed = true;
                                                    break;
                                                }
                                            }

                                            if (changed) {
                                                Alert.fire({
                                                    title: <p>Overwrite Categories?</p>,
                                                    text: 'You have assigned a category to 1 or more items in the upload queue. Changing them globally will overwrite ALL categories. Are you sure you would like to use this category for all files?',
                                                    showCancelButton: true,
                                                    confirmButtonText: 'Change Category',
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        for (let i = 1; i < document.getElementsByClassName('category-input').length; i++) {
                                                            const categoryField = document.getElementsByClassName('category-input')[i];
                                                            categoryField.value = e.target.value
                                                        }
                                                    }
                                                })
                                            }
                                        }}
                                    >
                                        <option value={"None"}>None</option>
                                        {categories?.map(category =>
                                            <option key={category} value={category}>{category}</option>
                                        )}
                                    </select>
                                </Tippy>
                            </div>
                        </div>

                        {/* File table */}
                        <table id='file-table'>
                            <thead>
                                <tr>
                                    <td className='name-header'>Name</td>
                                    <td className='category-header'>Category</td>
                                    <td className='type-header'>Type</td>
                                    <td className='size-header'>Size</td>
                                    <td className='progress-header'>Progress</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from(files).map((file, i) => {
                                    return (
                                        <tr key={i}>
                                            {/* Name */}
                                            <td>
                                                <input
                                                    type="text"
                                                    className='input name-input'
                                                    disabled={uploading ? true : false}
                                                    style={uploading ? { color: '#b7b7b7' } : { color: 'white' }}
                                                />
                                            </td>

                                            {/* Category */}
                                            <td >
                                                <select
                                                    id='note-type'
                                                    className='input category-input'
                                                    disabled={uploading ? true : false}
                                                    style={{ width: '125px', fontSize: '12px' }}
                                                    ref={categoryRef}
                                                >
                                                    <option value={"None"}>None</option>
                                                    {categories?.map(category =>
                                                        <option key={category} value={category}>{category}</option>
                                                    )}
                                                </select>
                                            </td>

                                            {/* Type */}
                                            <td>
                                                <span className='extension'>{file.name.split('.').pop().toUpperCase()}</span>
                                            </td>

                                            {/* File size */}
                                            <td>{funcs.humanFileSize(file.size, true)}</td>

                                            {/* Upload progress */}
                                            <td>
                                                <Progress
                                                    percent={fileProgress[i]}
                                                    status={fileProgress[i] === -1 ? "error" : ""}
                                                    symbolClassName="progress-symbol"
                                                />
                                            </td>

                                            {/* Add / remove from queue buttons */}
                                            <td style={{ paddingRight: '15px' }}>
                                                {uploading ?
                                                    <>
                                                        {!uploadInfo.concluded.includes(i) ?
                                                            <>
                                                                {uploadInfo.cancelled.includes(i) ?
                                                                    <Tippy content='Re-add To Upload' animation='scale-subtle' theme='custom'>
                                                                        <span
                                                                            style={{ cursor: 'pointer' }}
                                                                            className='grow'
                                                                            onClick={() => {
                                                                                var index = uploadInfo.cancelled.indexOf(i);
                                                                                if (index !== -1) {
                                                                                    uploadInfo.cancelled.splice(index, 1);
                                                                                    updateQueueBytes();
                                                                                }
                                                                            }}>
                                                                            <FontAwesomeIcon className='grow' icon={faUpload} />
                                                                        </span>
                                                                    </Tippy> :
                                                                    <Tippy content='Remove From Upload Queue' animation='scale-subtle' theme='custom'>
                                                                        <span
                                                                            style={{ cursor: 'pointer' }}
                                                                            className='grow'
                                                                            onClick={() => {
                                                                                if (uploadInfo.currentlyProcessing === i) {
                                                                                    Alert.fire({
                                                                                        title: <p>Cancel Upload?</p>,
                                                                                        text: 'You are actively uploading this file, are you sure you would like to cancel it? You must re-add this file later if you would like to still upload it.',
                                                                                        showCancelButton: true,
                                                                                        confirmButtonText: 'Remove',
                                                                                    }).then((result) => {
                                                                                        if (result.isConfirmed) {
                                                                                            if (uploadInfo.currentlyProcessing !== i) {
                                                                                                Alert.fire({
                                                                                                    text: 'This file has already been uploaded since you first clicked the remove button and cannot be removed from the upload queue.',
                                                                                                    showCancelButton: false,
                                                                                                    confirmButtonText: 'Okay',
                                                                                                })
                                                                                            } else {
                                                                                                uploadInfo.cancelled.push(i);
                                                                                                updateQueueBytes();
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                } else {
                                                                                    uploadInfo.cancelled.push(i);
                                                                                    updateQueueBytes();
                                                                                }
                                                                            }}>
                                                                            <FontAwesomeIcon className='grow' icon={faBan} />
                                                                        </span>
                                                                    </Tippy>
                                                                }
                                                            </> :
                                                            <FontAwesomeIcon icon={faMinus} />
                                                        }
                                                    </> :
                                                    // <FontAwesomeIcon icon={faMinus} />
                                                    <Tippy content='Remove From Upload Queue' animation='scale-subtle' theme='custom'>
                                                        <span
                                                            style={{ cursor: 'pointer' }}
                                                            className='grow'
                                                            onClick={() => {
                                                                Alert.fire({
                                                                    title: <p>Remove File From Queue?</p>,
                                                                    text: 'You will need to re-add this file in order to upload it again. Are you sure you would like to remove it?',
                                                                    showCancelButton: true,
                                                                    confirmButtonText: 'Remove',
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        let originalEntries = [...files];
                                                                        originalEntries.splice(i, 1);
                                                                        setFiles([...originalEntries]);
                                                                    }
                                                                })
                                                            }}>
                                                            <FontAwesomeIcon className='grow' icon={faBan} />
                                                        </span>
                                                    </Tippy>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    {/* Upload / cancel buttons */}
                    {!uploading ?
                        <button className='grow upload-button' onClick={() => { upload() }}>Upload</button> :
                        <button className='grow cancel-button' onClick={() => {
                            controller.abort();
                        }}>Cancel
                        </button>

                    }
                </>
            }
        </div >
    );
}